import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import SafeSiteImage, { transformImageUrl } from "./SafeSiteImage";
import Container from "./Container";
import Grid from "../components/Grid";
import styled, { css } from "styled-components";
import SisterSiteLogo from "./Icons/SisterSiteLogo";
import { convertToRelativePath } from "../utils/convertToRelativePath";
import SafeSiteLink from './SafeSiteLink';
import { HtmlContent } from "./Content";

const FooterStyles = styled.footer`
  ${({ ...props }) => css`
    padding: 72px 0;
    background-color: ${props.theme.colors.darkGrey};
    color: #fff;

    h3 {
      margin-bottom: 24px;
    }

    ul {
      li {
        & + li {
          margin-top: 8px;
        }
        a {
          &:hover {
            color: #fff;
            opacity: 0.8;
          }
        }
      }
    }

    address {
      font-style: normal;
      margin-bottom: 24px;
    }
  `}
`;

const FooterLogoStyles = styled.div`
  ${({ ...props }) => css`
    margin-top: 48px;

    img {
      width: 100%
    }

    img:last-child {
      display: none !important;
    }

    @media (min-width: ${props.theme.breakpoints.lg}) {
      img:first-child {
        display: none !important;
      }
      img:last-child {
        display: inline-block !important;
      }
    }
  `}
`;

const BannerStyles = styled.a`
  ${({ ...props }) => css`
    display: block;
    background-color: #fff;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    color: ${props.theme.colors.darkGrey};
    background-image: url('${props.$img}');
    background-size: cover;
    background-repeat: no-repeat;

    svg {
      margin-bottom: 16px;
    }

    .button {
      background-color: ${props.theme.colors.sisterSitePrimary};
      min-width: 200px;
      text-align: center;

      &:hover {
        background-color: ${props.theme.colors.sisterSiteDarkPrimary};
      }
    }

    .bannerContent {
      padding: 24px;
    }

    &:hover {
      color: ${props.theme.colors.sisterSiteDarkPrimary};
    }

    h3 {
      max-width: 200px;
    }

    @media (max-width: 991px) {
      h3 {
        font-size: .875rem;
        line-height: 18px;
      }

      svg {
        transform: scale(0.8);
        transform-origin: top left;
      }
    }

    @media (min-width: ${props.theme.breakpoints.lg}) {
      background-position-x: 50px;
      background-repeat: no-repeat;

      h3 {
        max-width: 50%;
      }
    }
  `}
`;

export const Footer = () => {
  const data = useStaticQuery(graphql`query FooterQuery {
    wp {
      siteSettings {
        siteSettings {
          address
          telephone
          registeredCompanyNumber
          bannerText
          buttonText
          image {
            mediaItemUrl
          }
          link
          footerLogosDesktop {
            mediaItemUrl
          }
          footerLogosMobile {
            mediaItemUrl
          }
        }
      }
    }
    wpMenu(name: {eq: "Footer Navigation"}) {
      menuItems {
        nodes {
          label
          path
          id
        }
      }
    }
    site {
      siteMetadata {
        wordpressSourceUrl
        siteUrl
      }
    }
  }`);

  const siteSettings = data.wp.siteSettings.siteSettings;
  const transformedImageUrl = transformImageUrl({ siteUrl: data.site.siteMetadata.siteUrl, imageUrl: siteSettings.image.mediaItemUrl, width: 570 })

  data.wpMenu.menuItems.nodes.forEach(item => {
    item.path = convertToRelativePath(item.path)
  })

  return (
    <FooterStyles>
      <Container>
        <Grid columnGap="loose">
          <Grid item md="2" columnGap="loose">
            <h3>Information</h3>
            <nav>
              <ul>
                {data.wpMenu.menuItems.nodes.map((item) => {
                  return (
                    <li key={item.id}>
                      <SafeSiteLink url={item.path} text={item.label} />
                    </li>
                  );
                })}
                <li>
                  <a href={`${data.site.siteMetadata.wordpressSourceUrl}/wp-login.php`} target="_blank">Login</a>
                </li>
              </ul>
            </nav>
          </Grid>
          <Grid item md="4" columnGap="loose">
            <h3>Contact us</h3>
            <address>
              <HtmlContent htmlString={siteSettings.address} />
            </address>
            <p>Call: {siteSettings.telephone}</p>
            <p>Registered Company: {siteSettings.registeredCompanyNumber}</p>
          </Grid>
          <Grid item md="6" columnGap="loose">
            <BannerStyles
              href={siteSettings.link}
              target="_blank"
              rel="noopener noreferrer"
              $img={transformedImageUrl}
            >
              <div className="bannerContent">
                <SisterSiteLogo />
                <h3>
                  <HtmlContent htmlString={siteSettings.bannerText} />
                </h3>
                <span className="button">{siteSettings.buttonText}</span>
              </div>
            </BannerStyles>
          </Grid>
        </Grid>
        <FooterLogoStyles>
          <SafeSiteImage
            image={siteSettings.footerLogosMobile.mediaItemUrl}
            alt="SafeSite Accreditation's"
            width="900"
          />
          <SafeSiteImage
            image={siteSettings.footerLogosDesktop.mediaItemUrl}
            alt="SafeSite Accreditation's"
            width="1204"
          />
        </FooterLogoStyles>
      </Container>
    </FooterStyles>
  );
};
