import React, { useEffect, useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled, { css } from "styled-components";
import Container from "./Container";
import CloseIcon from "./Icons/Close";
import SafeSiteImage from "./SafeSiteImage";
import { convertToRelativePath } from "../utils/convertToRelativePath";
import parse from 'html-react-parser';

const ContentStyles = styled.div`
  ${({ ...props }) => css`
    line-height: 24px;

    table {
      width: max-content;
      max-width: 100%;
    }

    p {
      margin-bottom: 16px;
    }

    h2 {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    h3 {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    ul,
    ol {
      list-style: disc;
      margin-bottom: 24px;
      padding-left: 16px;

      li + li {
        margin-top: 4px;
      }
    }

    a {
      color: ${props.theme.colors.primary};
      text-decoration: underline;
      display: inline-block;
    }

    // WP styles

    .lead {
      font-size: 1.125rem;
      line-height: 28px;
      color: ${props.theme.colors.primary};
    }

    .content-image-wrapper {
      display: inline-block
    }

    img {
      width: initial
    }

    iframe {
      width: 100%;
      aspect-ratio: 16 / 9;
      height: auto;
    }

    .img-polaroid,
    .content-image-wrapper,
    img {
      border-radius: 4px;
      overflow: hidden;
      border: solid 1px ${props.theme.colors.lightGrey};
      max-width: 100%;
      height: auto;

      @media (min-width: ${props.theme.breakpoints.md}) {
        max-width: calc(100% - 32px);
        margin: 16px;
      }
    }

    // Nuke top/bottom margin

    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  `}
`;

const ImageModalStyles = styled.div`
  ${({ ...props }) => css`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    z-index: 99999;
    background: #fff;
    padding: 96px 0;
    text-align: center;
    margin: 0!important;

    button {
      position: fixed;
      top: 32px;
      right: 32px;
      z-index: 999999;
      display: flex;
      align-items: center;
      svg {
        margin-right: 12px;
      }
    }

    img {
      margin: 0 auto;
      max-height: calc(100vh - 192px);
      width: 100%
    }

    @media (min-width: ${props.theme.breakpoints.md}) {
      img {
        width: auto;
      }
    }
  `}
`;

export const HtmlContent = ({ htmlString }) => {
  let data
  if (!process.env.STORYBOOK) {
    data = useStaticQuery(graphql`query ContentQuery {
      site {
        siteMetadata {
          wordpressSourceUrl
        }
      }
    }`);
  }

  const wordpressSourceUrl = data?.site?.siteMetadata?.wordpressSourceUrl

  const html = convertToRelativePath(htmlString);

  const options = {
    replace: (domNode) => {
      if (domNode.name === 'a') {
        let href = domNode.attribs.href

        // If the URL is a WP one but does not have the WP domain, add the WP domain of this site
        if (href.includes('wp-content') && !href.includes(wordpressSourceUrl)) {
          href = `${wordpressSourceUrl}/${href}`
        }

        domNode.attribs.href = href
      }
      if (domNode.name === 'img') {
        let src = domNode.attribs.src

        // Strip out the WordPress Size
        src = src.replace(/-(\d{3,4})x(\d{3,4})/, '')

        // If the URL is a WP one but does not have the WP domain, add the WP domain of this site
        if (src.includes('wp-content') && !src.includes(wordpressSourceUrl)) {
          src = `${wordpressSourceUrl}/${src}`
        }

        return (
          <div className="content-image-wrapper">
            <SafeSiteImage
              image={src}
              alt={domNode.attribs.alt}
              className={domNode.attribs.class}
              width={domNode.attribs.width}
              height={domNode.attribs.height}
            />
          </div>
        );
      }
    },
  };

  return parse(html, options);
};

export const Content = ({ content }) => {
  const [modalImage, setModalImage] = useState("");
  const [modalImageVisibility, setModalImageVisibility] = useState(false);

  const contentRef = useRef()

  useEffect(() => {
    contentRef.current.querySelectorAll("a").forEach((item) => {
      const linkHref = item.href;

      if (/jpg|png|webp|avif/.test(linkHref)) {
        item.addEventListener("click", (e) => {
          "use strict";
          if (e) {
            e.preventDefault();
            setModalImage(linkHref);
            setModalImageVisibility(true);
          }
        });
      }
    });
  });

  function handleCloseModal() {
    setModalImageVisibility(false);
    setModalImage(null);
  }

  return (
    <>
      <ContentStyles ref={contentRef}>
        <HtmlContent htmlString={content} />
      </ContentStyles>
      {modalImage && modalImageVisibility &&
        <ImageModalStyles>
          <Container>
            <button type="button" onClick={handleCloseModal}>
              <CloseIcon /> Close
            </button>
              <SafeSiteImage image={modalImage} width="1204" loading="eager" fetchpriority="high" decoding="sync" />
          </Container>
        </ImageModalStyles>
      }
    </>
  );
};
